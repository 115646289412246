import React, { useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import is from "is_js";
import MainLayout from "./MainLayout";
import ErrorLayout from "./ErrorLayout";

import { toast, ToastContainer } from "react-toastify";
import { CloseButton } from "components/common/Toast";

import Error404 from "components/errors/Error404";
import Error500 from "components/errors/Error500";

import TcohMain from "components/pages/TcohMain";
import LcohCalculator from "components/pages/LcohCalculator";
import UKEvForecast from "components/pages/UKEvForecast";

import Dashboard from "components/dashboards/default";
import AppContext from "context/Context";

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add("windows");
    }
    if (is.chrome()) {
      HTMLClassList.add("chrome");
    }
    if (is.firefox()) {
      HTMLClassList.add("firefox");
    }
    if (is.safari()) {
      HTMLClassList.add("safari");
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === "double-top") {
      HTMLClassList.add("double-top-nav-layout");
    }
    return () => HTMLClassList.remove("double-top-nav-layout");
  }, [navbarPosition]);

  return (
    <>
      <Routes>
        {/* //--- MainLayout Starts  */}

        <Route element={<MainLayout />}>
          {/*Dashboard*/}
          <Route path="/" element={<Dashboard />} />
          <Route path="dashboard/theory-of-change" element={<TcohMain />} />
          <Route
            path="dashboard/hydrogen-calculator"
            element={<LcohCalculator />}
          />
          <Route path="/dashboard/uk-ev-forecast" element={<UKEvForecast />} />
        </Route>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>

        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
