import React, { useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import PageHeader from "components/common/PageHeader";
import priya from "assets/img/Priya.jpeg";
import callum from "assets/img/Callum_Rae.jpg";
import Avatar from "components/common/Avatar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Image from "react-bootstrap/Image";
import { Row, Col, OverlayTrigger, Tooltip, Card } from "react-bootstrap";
import SoftBadge from "components/common/SoftBadge";
import Lcoh from "./projects/lcoh/Lcoh";

const LcohCalculator = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <PageHeader
        preTitle="Calculator model"
        title="Levelised Cost of Hydrogen Calculator"
        description="This Calculator has been developed to provide indicative levelised costs for hydrogen (LCOH) production in the UK. <br className='d-none.d-md-block' />
        The calculator uses data from a range of governmental and industry sources, to inform the CAPEX, Efficiency, Curtailment, Electrolyser type and size. <br className='d-none.d-md-block' /> 
        These are used along with the data input below to arrive at the LCOH.
        <br className='d-none.d-md-block' /> Use the data fields below to define your chosen Hydrogen production scenario. The resulting
        Levelised Cost of Hydrogen produced is shown in the <b>Results</b> section."
        className="mb-3"
        titleTag="h2"
      >
        <h5 className="d-inline-block">Contributors</h5>
        <br></br>
        <Row className="align-items-center">
          <Col xs="auto">
            <OverlayTrigger
              overlay={<Tooltip id="priya-lcoh">Priya Bhagavathy</Tooltip>}
            >
              <div>
                <Avatar src={priya} rounded="circle" size="2xl" />
              </div>
            </OverlayTrigger>
          </Col>
          <Col xs="auto">
            <OverlayTrigger
              overlay={<Tooltip id="callum-lcoh">Callum Rae</Tooltip>}
            >
              <div>
                <Avatar src={callum} rounded="circle" size="2xl" />
              </div>
            </OverlayTrigger>
          </Col>
        </Row>
      </PageHeader>
      <Row className="g-3 mb-3">
        <Col lg={8}>
          <Card className="mb-3">
            <Card.Body>
              <Row className="g-0">
                <Col xs={12}>
                  <h5 className="mt-2">
                    LCOH Calculator{" "}
                    <SoftBadge pill bg="primary" className="me-1">
                      Beta
                    </SoftBadge>
                  </h5>
                  <Lcoh />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="mb-3">
            <Card.Body>
              <Row className="g-0">
                <Col xs={12}>
                  <h5 className="mt-2">Help</h5>
                  <p>
                    {" "}
                    The levelized cost of hydrogen (LCOH) is the discounted
                    lifetime cost of building and operating a production asset,
                    expressed as a cost per unit (energy or weight) of hydrogen
                    produced. In other words, LCOH indicates the cost of
                    producing a unit of hydrogen (kg, kWh etc) considering all
                    the costs involved. The calculation of the LCOH is based on
                    the following formula:{" "}
                  </p>
                  <Image src="/img/lcohequation.png" width="50%" />
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <b>
                        <i>
                          I<sub>0 </sub>
                        </i>
                      </b>{" "}
                      - Investment expenditure in year 0
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <b>
                        <i>
                          I<sub>t </sub>
                        </i>
                      </b>{" "}
                      - Investment expenditure in year t (stack replacement
                      costs){" "}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <b>
                        <i>
                          E<sub>t</sub>
                        </i>
                      </b>{" "}
                      - Electricity consumed in year t including generation
                      costs, grid costs and taxes when applicable, and
                      electrolyser stack degradation.{" "}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <b>
                        <i>
                          M<sub>t</sub>
                        </i>
                      </b>{" "}
                      - Other operational expenditures in year t
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <b>
                        <i>
                          H<sub>t</sub>
                        </i>
                      </b>{" "}
                      - Hydrogen production in year t
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <b>
                        <i>r</i>
                      </b>{" "}
                      - Discount rate (assumed as 10%){" "}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <b>
                        <i>n</i>
                      </b>{" "}
                      - Life of the system in years (assumed as 25 years)
                    </ListGroup.Item>
                  </ListGroup>
                  <br className="d-none.d-md-block" />
                  <Button variant="primary" onClick={() => setModalShow(true)}>
                    {" "}
                    Assumptions & Acknowledgements{" "}
                  </Button>
                  <Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                        More Information
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Tabs defaultActiveKey="acknowledge" id="lcohassumptions">
                        <Tab
                          eventKey="acknowledge"
                          title="Acknowledgement"
                          className="border-bottom border-x p-3"
                        >
                          <p>
                            Authors would like acknowledge funding recieved from
                            the Low Carbon Challenge Fund (ERDF funding) and the
                            Hydrogen Accelerator
                          </p>
                          <Image
                            src="/img/ESF-logo-English-colour-JPG-1.webp"
                            height="150px"
                            width="200px"
                          />
                        </Tab>
                        <Tab
                          eventKey="assumptions"
                          title="Assumptions"
                          className="border-bottom border-x p-3"
                        >
                          <ListGroup variant="flush">
                            <ListGroup.Item>
                              Operational costs are 1.5% of CAPEX
                            </ListGroup.Item>
                            <ListGroup.Item>
                              Operational life expectancy of 30 years
                            </ListGroup.Item>
                            <ListGroup.Item>
                              Stack replacement costs are 33% of CAPEX
                            </ListGroup.Item>
                            <ListGroup.Item>
                              Discount rate of 10%
                            </ListGroup.Item>
                            <ListGroup.Item>
                              Currency conversion as of July 2022{" "}
                            </ListGroup.Item>
                          </ListGroup>
                        </Tab>
                        <Tab
                          eventKey="reference"
                          title="References"
                          className="border-bottom border-x p-3"
                        >
                          <ListGroup variant="flush">
                            <ListGroup.Item>
                              Lazard's Levelised Cost of Hydrogen report (2021)
                            </ListGroup.Item>
                            <ListGroup.Item>
                              BEIS Hydrogen Production Costs (2021){" "}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              BEIS Non-domestic fuel price data (2021)
                            </ListGroup.Item>
                          </ListGroup>
                        </Tab>
                      </Tabs>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={() => setModalShow(false)}>Close</Button>
                    </Modal.Footer>
                  </Modal>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default LcohCalculator;
