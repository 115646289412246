import React from "react";
import { Col, Row } from "react-bootstrap";
import SoftBadge from "components/common/SoftBadge";

const Footer = () => (
  <footer className="footer">
    <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
      <Col sm="auto">
        <p className="mb-0 text-600">
          Thank you for using PriyaResearch{" "}
          <span className="d-none d-sm-inline-block">| </span>
          <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{" "}
          <a href="https://priyaresearch.com">PriyaResearch</a>
        </p>
      </Col>
      <Col sm="auto">
        <h6 className="mb-0 text-600">
          v1.1
          <span>
            <SoftBadge bg="info" className="me-2">
              Prod
            </SoftBadge>
          </span>
        </h6>
      </Col>
    </Row>
  </footer>
);

export default Footer;
