import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function EchartPage() {
  const [isLoading, setLoadingState] = useState(true);
  const [loadedData, setLoadedData] = useState([]);

  useEffect(() => {
    setLoadingState(true);
    fetch(
      //"https://theoryofchange-b866b-default-rtdb.europe-west1.firebasedatabase.app/data.json"
      // "https://aocg9d.deta.dev/toc/"
      "/data/beistocdata.json",
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    )
      .then(response => {
        return response.json();
      })
      .then(data => {
        // const eChartsRef = React.useRef(null);
        let echartoptions = {
          tooltip: {
            trigger: "item",
            triggerOn: "mousemove"
          },
          series: [
            {
              type: "sankey",
              data: data.nodes,
              links: data.links,
              // focus: 'adjacency',
              emphasis: {
                focus: "adjacency"
              },
              //focusNodeAdjacency: true,
              nodeAlign: "left",
              levels: [
                {
                  depth: 0,
                  itemStyle: {
                    color: "#fbb4ae"
                  },
                  lineStyle: {
                    color: "source",
                    opacity: 0.6
                  },
                  label: {
                    color: "black"
                  }
                },
                {
                  depth: 1,
                  itemStyle: {
                    color: "#b3cde3"
                  },
                  lineStyle: {
                    color: "source",
                    opacity: 0.6
                  },
                  label: {
                    color: "black"
                  }
                },
                {
                  depth: 2,
                  itemStyle: {
                    color: "#ccebc5"
                  },
                  lineStyle: {
                    color: "source",
                    opacity: 0.6
                  },
                  label: {
                    color: "black"
                  }
                },
                {
                  depth: 3,
                  itemStyle: {
                    color: "#decbe4"
                  },
                  lineStyle: {
                    color: "source",
                    opacity: 0.6
                  },
                  label: {
                    color: "black"
                  }
                },
                {
                  depth: 4,
                  itemStyle: {
                    color: "#cf3e6f"
                  },
                  lineStyle: {
                    color: "source",
                    opacity: 0.6
                  },
                  label: {
                    color: "black"
                  }
                }
              ],
              itemStyle: {
                borderWidth: 1,
                borderColor: "#aaa"
              },
              lineStyle: {
                color: "source",
                curveness: 0.5
              }
            }
          ]
        };

        setLoadedData(echartoptions);
        setLoadingState(false);
      });
  }, []);

  if (isLoading) {
    return (
      <section>
        <p>Loading....</p>
      </section>
    );
  }

  return (
    <div>
      <div className="gap-10 inline">
        <span className="d-inline  float-left">
          <FontAwesomeIcon icon="square" className="activity" />
          &nbsp;Inputs
        </span>
        &nbsp;&nbsp;
        <span className="d-inline  float-left">
          <FontAwesomeIcon icon="square" className="sterm" />
          &nbsp;Activities
        </span>
        &nbsp;&nbsp;
        <span className="d-inline  float-left">
          <FontAwesomeIcon icon="square" className="lterm" />
          &nbsp;Outputs
        </span>
        &nbsp;&nbsp;
        <span className="d-inline  float-left">
          <FontAwesomeIcon icon="square" className="outcome" />
          &nbsp;Outcomes
        </span>
        &nbsp;&nbsp;
        <span className="d-inline  float-left">
          <FontAwesomeIcon icon="square" className="ebenefit" />
          &nbsp;End Benefits/Impacts
        </span>
        &nbsp;&nbsp;
      </div>
      <ReactECharts
        option={loadedData}
        notMerge={true}
        style={{ height: "1008px" }}
      />
    </div>
  );
}

export default EchartPage;
