import React, { useEffect, useRef } from "react";
import ReactEcharts from "echarts-for-react";
import _ from "lodash";
import PropTypes from "prop-types";

// Your ForecastChart component code...

function ForecastChart({ formData, formData2 }) {
  const chartRef = useRef(null);

  // const [seriesData, setSeriesData] = useState([]);

  // console.log("formData2", formData2);

  // Define a consistent color palette for regions
  const colorPalette = [
    "#5470c6",
    "#91cc75",
    "#fac858",
    "#ee6666",
    "#73c0de",
    "#3ba272",
    "#fc8452"
  ];

  const prevFormDataRef = useRef(null);
  const prevFormData2Ref = useRef(null);

  useEffect(() => {
    if (formData && formData.length > 0) {
      const allRegions = new Set(formData.map(d => d.region));
      if (formData2) {
        formData2.forEach(d => allRegions.add(d.region));
      }

      const newSeriesData = Array.from(allRegions).flatMap((region, index) => {
        const data1 = formData.find(d => d.region === region);
        const data2 = formData2
          ? formData2.find(d => d.region === region)
          : null;

        const series = [];
        if (data1 && data1.s_curve_values) {
          series.push({
            name: region,
            type: "line",
            data: data1.s_curve_values,
            lineStyle: { type: "solid" },
            itemStyle: { color: colorPalette[index % colorPalette.length] }
          });
        }

        if (data2 && data2.s_curve_values) {
          series.push({
            name: region + " (2019 Forecast)",
            type: "line",
            data: data2.s_curve_values,
            lineStyle: { type: "dashed" },
            itemStyle: { color: colorPalette[index % colorPalette.length] }
          });
        }

        return series;
      });

      const newOptions = {
        ...options,
        legend: { data: newSeriesData.map(s => s.name) },
        xAxis: {
          type: "category",
          data: formData[0].projection_years,
          boundaryGap: false
        },
        yAxis: {
          type: "value",
          axisLabel: { formatter: value => value + "%" }
        },
        series: newSeriesData
      };

      // setSeriesData(newSeriesData);
      if (chartRef.current) {
        const echartsInstance = chartRef.current.getEchartsInstance();
        echartsInstance.clear(); // Clear the chart before updating
        echartsInstance.setOption(newOptions);
      }
    }

    // Store the current data as previous for the next comparison
    prevFormDataRef.current = formData;
    prevFormData2Ref.current = formData2;
  }, [
    _.isEqual(formData, prevFormDataRef.current),
    _.isEqual(formData2, prevFormData2Ref.current)
  ]);

  const options = {
    tooltip: { trigger: "axis" },
    grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
    toolbox: { feature: { saveAsImage: {} } }
  };

  return <ReactEcharts ref={chartRef} option={options} />;
}

// {ons_code: 'E92000001', projection_years: Array(208), s_curve_values: Array(208), adoption_rates: Array(56), region: 'England'}

ForecastChart.propTypes = {
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      ons_code: PropTypes.string.isRequired,
      adoption_rates: PropTypes.arrayOf(PropTypes.number).isRequired,
      region: PropTypes.string.isRequired,
      s_curve_values: PropTypes.arrayOf(PropTypes.number).isRequired,
      projection_years: PropTypes.arrayOf(PropTypes.number).isRequired
    })
  ).isRequired,
  formData2: PropTypes.arrayOf(
    PropTypes.shape({
      ons_code: PropTypes.string.isRequired,
      adoption_rates: PropTypes.arrayOf(PropTypes.number).isRequired,
      region: PropTypes.string.isRequired,
      s_curve_values: PropTypes.arrayOf(PropTypes.number).isRequired,
      projection_years: PropTypes.arrayOf(PropTypes.number).isRequired
    })
  )
};

export default ForecastChart;
