export const dashboardRoutes = {
  label: "Dashboard",
  labelDisable: true,
  children: [
    {
      name: "Projects",
      active: true,
      icon: "chart-pie",
      children: [
        {
          name: "All projects",
          to: "/",
          exact: true,
          active: true
        },
        {
          name: "Theory of Change",
          to: "/dashboard/theory-of-change",
          active: true
        },
        {
          name: "Hydrogen Calculator",
          to: "/dashboard/hydrogen-calculator",
          active: true
        },
        {
          name: "UK EV Forecast Model",
          to: "/dashboard/uk-ev-forecast",
          active: true
        }
      ]
    }
  ]
};
export const appRoutes = {};

export const pagesRoutes = {};

export const modulesRoutes = {};

export const documentationRoutes = {};

export default [dashboardRoutes];
