import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.min.css";
import Layout from "./layouts/Layout";
import ReactGA from "react-ga4";

const App = () => {
  ReactGA.initialize("G-4PC4Q7G5JY");
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout />
    </Router>
  );
};

export default App;
