import React from "react";
import SimpleBarReact from "simplebar-react";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function LcohResponse(props) {
  const data = props.formResponse;
  if (data) {
    return (
      <div>
        <SimpleBarReact className="ask-analytics">
          <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3">
            <Flex alignItems="center" className="mb-3">
              <FontAwesomeIcon icon="weight" className="text-primary" />
              <Link to="#!" className="stretched-link text-decoration-none">
                <h5 className="fs--1 text-600 mb-0 ps-3">Hydrogen produced</h5>
              </Link>
            </Flex>
            <h5 className="fs--1 text-800">
              {data.kg_Hydrogen &&
                Intl.NumberFormat("en-US").format(
                  data.kg_Hydrogen.toFixed(2)
                )}{" "}
              kg/year
            </h5>
          </div>
        </SimpleBarReact>
        <SimpleBarReact className="ask-analytics">
          <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3">
            <Flex alignItems="center" className="mb-3">
              <FontAwesomeIcon icon="pound-sign" className="text-primary" />
              <Link to="#!" className="stretched-link text-decoration-none">
                <h5 className="fs--1 text-600 mb-0 ps-3">
                  Levelised Cost of Hydrogen per kg
                </h5>
              </Link>
            </Flex>
            <h5 className="fs--1 text-800">
              {data.LCOH_kg &&
                Intl.NumberFormat("en-US").format(data.LCOH_kg.toFixed(2))}{" "}
              GBP/kg
            </h5>
          </div>
        </SimpleBarReact>
        <SimpleBarReact className="ask-analytics">
          <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3">
            <Flex alignItems="center" className="mb-3">
              <FontAwesomeIcon icon="pound-sign" className="text-primary" />
              <Link to="#!" className="stretched-link text-decoration-none">
                <h5 className="fs--1 text-600 mb-0 ps-3">
                  Levelised Cost of Hydrogen per Nm3
                </h5>
              </Link>
            </Flex>
            <h5 className="fs--1 text-800">
              {data.LCOH_Nm3 &&
                Intl.NumberFormat("en-US").format(
                  data.LCOH_Nm3.toFixed(2)
                )}{" "}
              GBP/Nm3
            </h5>
          </div>
        </SimpleBarReact>
        <SimpleBarReact className="ask-analytics">
          <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3">
            <Flex alignItems="center" className="mb-3">
              <FontAwesomeIcon icon="pound-sign" className="text-primary" />
              <Link to="#!" className="stretched-link text-decoration-none">
                <h5 className="fs--1 text-600 mb-0 ps-3">
                  Levelised Cost of Hydrogen per MW
                </h5>
              </Link>
            </Flex>
            <h5 className="fs--1 text-800">
              {data.LCOH_MW &&
                Intl.NumberFormat("en-US").format(data.LCOH_MW.toFixed(2))}{" "}
              GBP/MW
            </h5>
          </div>
        </SimpleBarReact>
      </div>
    );
  }
  return (
    <div>
      <h2>No data found</h2>
    </div>
  );
}

LcohResponse.propTypes = {
  formResponse: PropTypes.shape({
    kg_Hydrogen: PropTypes.number,
    LCOH_kg: PropTypes.number,
    LCOH_Nm3: PropTypes.number,
    LCOH_MW: PropTypes.number
  })
};

export default LcohResponse;
