import React from "react";
import {
  Col,
  Row,
  Card,
  Button,
  OverlayTrigger,
  Tooltip,
  Container
} from "react-bootstrap";
import priya from "assets/img/Priya.jpeg";
import callum from "assets/img/Callum_Rae.jpg";
import hannah from "assets/img/hannah.jpeg";
import Avatar from "components/common/Avatar";
import Flex from "components/common/Flex";
import PageHeader from "components/common/PageHeader";
import SoftBadge from "components/common/SoftBadge";

const Dashboard = () => {
  return (
    <>
      <PageHeader
        preTitle="Projects"
        title="Projects, Tools & Data"
        description="Net Zero projects on Policy, Technology and Industry covering decarbonisation, renewables and storage."
        className="mb-3"
        titleTag="h2"
      ></PageHeader>
      <Container fluid>
        <Row xs="auto">
          <Col as={Flex}>
            <Card style={{ width: "20rem" }} className="mb-3">
              {/* <Card.Img src={proj1} variant='top'/> */}
              <Card.Body>
                <Card.Title as="h5">Theory of Change</Card.Title>
                <Card.Text>
                  An evaluation framework for simultaneous public interventions.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <div>
                  <Row className="align-items-center">
                    <Col xs="auto">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="priya-lcoh">Priya Bhagavathy</Tooltip>
                        }
                      >
                        <div>
                          <Avatar src={priya} rounded="circle" size="2xl" />
                        </div>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </div>

                <Button
                  color="primary"
                  size="sm"
                  href="/dashboard/theory-of-change"
                >
                  Details
                </Button>
              </Card.Footer>
            </Card>
          </Col>
          <Col as={Flex}>
            <Card style={{ width: "20rem" }} className="mb-3">
              {/* <Card.Img src={proj2} variant='top'/> */}
              <Card.Body>
                <Card.Title as="h5">
                  Levelised Cost of Hydrogen Calculator
                </Card.Title>
                <Card.Text>
                  Calculate the levelised cost against multiple scenarios
                  covering CAPEX, Efficiency, Curtailment, Electrolyser type and
                  size.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <div>
                  <Row className="align-items-center">
                    <Col xs="auto">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="priya-lcoh">Priya Bhagavathy</Tooltip>
                        }
                      >
                        <div>
                          <Avatar src={priya} rounded="circle" size="2xl" />
                        </div>
                      </OverlayTrigger>
                    </Col>
                    <Col xs="auto">
                      <OverlayTrigger
                        overlay={<Tooltip id="callum-lcoh">Callum Rae</Tooltip>}
                      >
                        <div>
                          <Avatar src={callum} rounded="circle" size="2xl" />
                        </div>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </div>
                <Button
                  color="primary"
                  size="sm"
                  href="/dashboard/hydrogen-calculator"
                >
                  Details
                </Button>
              </Card.Footer>
            </Card>
          </Col>
          <Col as={Flex}>
            <Card style={{ width: "20rem" }} className="mb-3">
              {/* <Card.Img src={proj2} variant='top'/> */}
              <Card.Body>
                <Card.Title as="h5">
                  UK EV Forecast Model
                  <SoftBadge pill bg="primary" className="me-1">
                    Updated Q2-2024
                  </SoftBadge>
                </Card.Title>
                <Card.Text>
                  Compare the current rate of EV growth across UK regions
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <div>
                  <Row className="align-items-center">
                    <Col xs="auto">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="priya-evcalc">
                            Dr. Priya Bhagavathy
                          </Tooltip>
                        }
                      >
                        <div>
                          <Avatar src={priya} rounded="circle" size="2xl" />
                        </div>
                      </OverlayTrigger>
                    </Col>
                    <Col xs="auto">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="hannah-evcalc">
                            Dr. Hannah Budnitz
                          </Tooltip>
                        }
                      >
                        <div>
                          <Avatar src={hannah} rounded="circle" size="2xl" />
                        </div>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </div>
                <Button
                  color="primary"
                  size="sm"
                  href="/dashboard/uk-ev-forecast"
                >
                  Details
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
