import axios from "axios";
import React, { useState } from "react";
import LcofForm from "./LcohForm";
import LcohResponse from "./LcohResponse";
import Flex from "components/common/Flex";
import signalImg from "assets/img/icons/signal.png";
import { Row, Col, Container, Card, Spinner } from "react-bootstrap";

function Lcoh() {
  const [calculatedValue, setCalculatedValue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  function lcohDataHandler(lcohData) {
    setIsLoading(true);
    const client = axios.create({
      //baseURL: "http://127.0.0.1:8000/lcoh/"
      //baseURL: "https://aocg9d.deta.dev/lcoh/"
      baseURL: "https://api.kaapi.pw/lcoh/"
    });

    const formdata = new URLSearchParams(lcohData).toString();

    client
      .get("?" + formdata)
      .then(res => {
        setIsLoading(true);
        setCalculatedValue(res.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
    setIsLoading(true);
  }
  return (
    <Container>
      <Row>
        <Col lg={7}>
          <Card className="mb-3">
            <Card.Body>
              <LcofForm calculateLcohData={lcohDataHandler} />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={5}>
          <Card className="mb-3">
            <Card.Header as={Flex} alignItems="center">
              <img
                src={signalImg}
                alt="intelligence"
                height={35}
                className="me-2"
              />
              <h5 className="fs-0 fw-normal text-800 mb-0">Results</h5>
            </Card.Header>
            <Card.Body>
              {/* {isLoading && (<div><Spinner animation="grow" variant="primary" /><Spinner animation="grow" variant="primary" /><Spinner animation="grow" variant="primary" /><Spinner animation="grow" variant="primary" /><Spinner animation="grow" variant="primary" /></div>)}
              <LcohResponse formResponse={calculatedValue}/> */}
              {isLoading ? (
                <div>
                  <Spinner animation="grow" variant="primary" />
                  <Spinner animation="grow" variant="primary" />
                  <Spinner animation="grow" variant="primary" />
                  <Spinner animation="grow" variant="primary" />
                  <Spinner animation="grow" variant="primary" />
                </div>
              ) : (
                <LcohResponse formResponse={calculatedValue} />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Lcoh;
