import React, { useRef, useState } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";

function LcofForm(props) {
  const plantCapacityRef = useRef();
  const electrolyserTypeRef = useRef();
  const electrolyserEfficiencyInputRef = useRef();
  const electrolyserCapexInputRef = useRef();
  const stackDurabilityInputRef = useRef();
  const setCurtailedInputRef = useRef();
  const curtailedValueInputRef = useRef();

  const [isHidden, setHidden] = useState(true);
  const [validated, setValidated] = useState(false);

  //const handleChange = event => {
  const handleChange = () => {
    setCurtailedInputRef.current.checked ? setHidden(false) : setHidden(true);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const form = event.currentTarget;

    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    const inputPlantCapacity = plantCapacityRef.current.value;
    const inputPlantType = electrolyserTypeRef.current.value;
    const inputPlantEfficiency = electrolyserEfficiencyInputRef.current.value;
    const inputCapexScenario = electrolyserCapexInputRef.current.value;
    const inputStackDurability = stackDurabilityInputRef.current.value;
    const inputSetCurtailed = setCurtailedInputRef.current.checked;
    const inputCurtailedValue = curtailedValueInputRef.current.value;

    if (form.checkValidity()) {
      const lcohData = {
        plantCapacityInput: inputPlantCapacity,
        ElectrolyserTypeselect: inputPlantType,
        ElectrolyserEfficiencyCategoryselect: inputPlantEfficiency,
        CapitalInvestmentScenarioselect: inputCapexScenario,
        StackDurabilityScenarioselect: inputStackDurability,
        curtailed: inputSetCurtailed,
        DiscountInput: inputCurtailedValue
      };
      event.preventDefault();
      props.calculateLcohData(lcohData);
    }
  };

  return (
    <Container className="mt-3">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="validationCustom01">
          <Form.Label>Plant Capacity</Form.Label>
          <Form.Control
            required
            type="number"
            step="0.001"
            min="0.001"
            placeholder="in MW"
            ref={plantCapacityRef}
          />
          <Form.Control.Feedback type="invalid">
            Please provide the Plant Capacity in MW between 0.001 MW and
            1000,000 MW (Max 3 decimal digits allowed).
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formSelect1">
          <Form.Label>Type of Electrolyser</Form.Label>
          <Form.Select ref={electrolyserTypeRef}>
            <option value="1">PEM</option>
            <option value="2">Alkaline</option>
            <option value="3">Solid Oxide</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formSelect2">
          <Form.Label>Electroyser Efficiency</Form.Label>
          <Form.Select ref={electrolyserEfficiencyInputRef}>
            <option value="1">High</option>
            <option value="2">Medium</option>
            <option value="3">Low</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formSelect3">
          <Form.Label>Capital Investment Scenario</Form.Label>
          <Form.Select ref={electrolyserCapexInputRef}>
            <option value="1">High</option>
            <option value="2">Medium</option>
            <option value="3">Low</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formSelect4">
          <Form.Label>Stack Durability Scenario</Form.Label>
          <Form.Select ref={stackDurabilityInputRef}>
            <option value="1">High</option>
            <option value="2">Medium</option>
            <option value="3">Low</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Label>Energy from Curtailed Source?</Form.Label>
          {/* <Form.Check type="checkbox" ref={setCurtailedInputRef} onChange={handleChange}/> */}
          <Form.Check
            type="switch"
            id="defaultSwitch"
            ref={setCurtailedInputRef}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group
          className="mb-3"
          controlId="validationCustom02"
          hidden={isHidden}
          checked={!isHidden}
        >
          <Form.Label>% Discount</Form.Label>
          <Form.Control
            type="text"
            placeholder="%"
            defaultValue="50"
            ref={curtailedValueInputRef}
          />
          <Form.Control.Feedback type="invalid">
            Please provide the Discount in %.
          </Form.Control.Feedback>
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
}
LcofForm.propTypes = {
  calculateLcohData: PropTypes.func
};

export default LcofForm;
