import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import priya from "assets/img/Priya.jpeg";
import Avatar from "components/common/Avatar";

const ProfileDropdown = () => {
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={priya} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item className="fw-bold text-warning" href="#!">
            <span>Priya Bhagavathy</span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            href="https://www.linkedin.com/in/sivapriya/"
            target="_blank"
          >
            Profile
          </Dropdown.Item>
          <Dropdown.Item href="https://priyaresearch.com" target="_blank">
            Blog
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
