import React, { useState, useEffect } from "react";
import PageHeader from "components/common/PageHeader";
import priya from "assets/img/Priya.jpeg";
import hannah from "assets/img/hannah.jpeg";
import axios from "axios";
import Avatar from "components/common/Avatar";
import { Row, Col, OverlayTrigger, Tooltip, Card } from "react-bootstrap";
import ForecastChart from "./projects/ev-forecast/ForecastChart";
import ForecastForm from "./projects/ev-forecast/ForecastForm";

const UKEvForecast = () => {
  const [formData, setFormData] = useState(null);
  const [EVFormData, setEVFormData] = useState({
    ons_codes: ["E92000001"],
    prev_forecasts_based: false
  });
  const [formData2, setFormData2] = useState(null);

  useEffect(() => {
    EvFormDataHandler();
  }, []);

  async function EvFormDataHandler(newData = null) {
    try {
      const updatedFormData = newData
        ? { ...EVFormData, ...newData }
        : EVFormData;
      setEVFormData(updatedFormData);

      let response1, response2;
      response1 = await axios.post(
        // 'http://127.0.0.1:8000/ev_adoption_results/',
        "https://api.kaapi.pw/ev_adoption_results/",
        { ...updatedFormData, prev_forecasts_based: false }
      );
      setFormData(response1.data);

      if (updatedFormData.prev_forecasts_based) {
        response2 = await axios.post(
          // 'http://127.0.0.1:8000/ev_adoption_results/',
          "https://api.kaapi.pw/ev_adoption_results/",
          { ...updatedFormData, prev_forecasts_based: true }
        );
        setFormData2(response2.data);
      } else {
        setFormData2(null); // Clear formData2 if not needed
      }
    } catch (error) {
      console.error("Error fetching forecast data:", error);
      // Handle errors appropriately
    }
  }
  return (
    <>
      <PageHeader
        preTitle="UK EV Forecast"
        title="Is the UK's electric vehicle adoption on track to the commitments?"
        description="A simple comparison model to visualise the adoption of electric vehicles in the UK."
        className="mb-3"
        titleTag="h2"
      >
        <div>
          <h5 className="d-inline-block">Contributors</h5>
        </div>

        <br></br>
        <Row className="align-items-center">
          <Col xs="auto">
            <OverlayTrigger
              overlay={<Tooltip id="priya-ukev">Dr Priya Bhagavathy</Tooltip>}
            >
              <div>
                <Avatar src={priya} rounded="circle" size="2xl" />
              </div>
            </OverlayTrigger>
          </Col>
          <Col xs="auto">
            <OverlayTrigger
              overlay={<Tooltip id="hannah-ukev">Dr. Hannah Budnitz</Tooltip>}
            >
              <div>
                <Avatar src={hannah} rounded="circle" size="2xl" />
              </div>
            </OverlayTrigger>
          </Col>
        </Row>
      </PageHeader>
      <Row className="g-3 mb-3 ">
        <Col lg={8}>
          <Card className="mb-3">
            <Card.Body>
              <Row className="g-0">
                <Col xs={12}>
                  <h5 className="mt-2">EV Forecast for UK</h5>
                  <ForecastChart formData={formData} formData2={formData2} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="mb-3">
            <Card.Body>
              <Row className="g-0">
                <Col xs={12}>
                  <h5 className="mt-2">Region Selection Controls</h5>
                  <ForecastForm EvFormDataSubmit={EvFormDataHandler} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UKEvForecast;
