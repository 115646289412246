import React, { useRef, useState, useEffect } from "react";
import { Container, Form, Button } from "react-bootstrap";
import PropTypes from "prop-types";

function ForecastForm(props) {
  const multiSelectRef = useRef();
  const checkboxRef = useRef();
  const regionSelectRef = useRef();

  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [prev_forecasts_based, setprev_forecasts_based] = useState(false);
  const [validated, setValidated] = useState(false);
  const [region, setRegion] = useState("All");

  // Populate options from a JSON on load
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        let response;
        if (region === "All") {
          response = await fetch("https://api.kaapi.pw/regions/");
          // response = await fetch("http://127.0.0.1:8000/regions/");
        } else {
          response = await fetch(
            `https://api.kaapi.pw/regions/?region=${region}`
          );
          // response = await fetch(
          //   `http://127.0.0.1:8000/regions/?region=${region}`
          // );
        }
        const data = await response.json();

        // Define priority order for regions
        const priorityOrder = ["Great Britain", "England", "Scotland", "Wales"];

        // Sort the data based on the priority order and then alphabetically
        data.sort((a, b) => {
          const aIndex = priorityOrder.indexOf(a.label);
          const bIndex = priorityOrder.indexOf(b.label);

          if (aIndex !== -1 && bIndex !== -1) {
            // Both regions are in the priority order, sort based on their index
            return aIndex - bIndex;
          } else if (aIndex === -1 && bIndex === -1) {
            // Neither region is in the priority order, sort alphabetically
            return a.label.localeCompare(b.label);
          } else {
            // One region is in the priority order and the other isn't, prioritize based on index
            return bIndex - aIndex;
          }
        });

        setOptions(data); // Set the sorted JSON response
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, [region]);

  const handleRegionChange = () => {
    const selectedRegion = regionSelectRef.current.value;
    setRegion(selectedRegion);
  };

  const handleSelectChange = () => {
    const values = Array.from(
      multiSelectRef.current.selectedOptions,
      option => option.value
    );
    if (values.length > 0 && values.length <= 3) {
      setSelectedOptions(values);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const form = event.currentTarget;

    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
      return; // Stop submission if not valid
    }

    setValidated(true);

    const selectedOptionObjects = selectedOptions.map(label =>
      options.find(option => option.label === label)
    );

    const selectedIds = selectedOptionObjects.map(
      option => option && option.id
    );

    const EVFormData = {
      ons_codes: selectedIds, // Use 'ons_codes' as the key
      prev_forecasts_based: checkboxRef.current.checked
    };

    props.EvFormDataSubmit(EVFormData);
    // console.log(EVFormData);
  };

  return (
    <Container className="mt-3">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId="ForecastForm.RegionSelect">
          <Form.Label>Region:</Form.Label>
          <Form.Control
            as="select"
            value={region}
            onChange={handleRegionChange}
            ref={regionSelectRef}
          >
            <option value="All">All</option>
            <option value="England">England</option>
            <option value="Scotland">Scotland</option>
            <option value="Wales">Wales</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="ForecastForm.MultiSelect">
          <Form.Label>
            Select up to 3 Regions (use Ctrl for multi-select):
          </Form.Label>
          <Form.Control
            as="select"
            multiple
            ref={multiSelectRef}
            value={selectedOptions}
            onChange={handleSelectChange}
          >
            {options.map(option => (
              <option key={option.id} value={option.label}>
                {option.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="ForecastForm.Checkbox" className="mt-3">
          <Form.Check
            type="checkbox"
            label="Compare with 2019 forecast"
            ref={checkboxRef}
            checked={prev_forecasts_based}
            onChange={() => setprev_forecasts_based(!prev_forecasts_based)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
}

ForecastForm.propTypes = {
  EvFormDataSubmit: PropTypes.func.isRequired
};

export default ForecastForm;
