import React from "react";
import PageHeader from "components/common/PageHeader";
import priya from "assets/img/Priya.jpeg";

import Avatar from "components/common/Avatar";
import { Row, Col, OverlayTrigger, Tooltip, Card } from "react-bootstrap";

import EchartPage from "components/pages/projects/change-theory/EchartPage";

const TcohMain = () => {
  return (
    <>
      <PageHeader
        preTitle="Theory of Change"
        title="Have the cats been herded?"
        description="An evaluation framework for simultaneous public interventions."
        className="mb-3"
        titleTag="h2"
      >
        <div>
          <h5 className="d-inline-block">Contributors</h5>
        </div>

        <br></br>
        <Row className="align-items-center">
          <Col xs="auto">
            <OverlayTrigger
              overlay={<Tooltip id="priya-lcoh">Priya Bhagavathy</Tooltip>}
            >
              <div>
                <Avatar src={priya} rounded="circle" size="2xl" />
              </div>
            </OverlayTrigger>
          </Col>
        </Row>
      </PageHeader>
      <Row className="g-3 mb-3">
        <Col lg={12}>
          <Card className="mb-3">
            <Card.Body>
              <Row className="g-0">
                <Col xs={12}>
                  <EchartPage />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default TcohMain;
